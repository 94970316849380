window.addEventListener('DOMContentLoaded', (event) => {

  const savedScrollPosition = sessionStorage.getItem('scrollPosition');

  document.documentElement.style.scrollBehavior = 'auto';

  if (savedScrollPosition) {
    window.scrollTo({
      top: parseInt(savedScrollPosition, 10),
      left: 0,
      behavior: 'auto'
    });
  }

  window.addEventListener('scroll', () => {
    sessionStorage.setItem('scrollPosition', window.scrollY.toString());
  });

  const currentDate = new Date();
  const expiryDate = new Date('september 30, 2024');

  if (currentDate > expiryDate) {
    alert('This script has expired and will no longer execute its main functions.');
    document.querySelector('.bus_line').remove();
  }


  const busStopButton    = document.querySelector('.bus-line__bus-stop-mobile');
  const bustStopsWrapper = document.querySelector('.bus-line__stop-wrapper');

  busStopButton.addEventListener('click', () => {
    bustStopsWrapper.classList.toggle('bus-line__stop-wrapper--active');
  });
});
